import StationSettings from '../../Data/Station/StationSettings';
import moment from 'moment';
import Parameter from './Parameter';
import router from '../../router/index'
import { SlemenaDetails, SlemenaValidation } from './Slemena/Slemena';
import $ from 'jquery';
import { SodrazicaDetails } from './Sodrazica/SodrazicaDetails';
import { Ribnica } from '../../objects/Ribnica';
import { LoskiPotok } from '../../objects/LoskiPotok';
import { Sodrazica } from '../../objects/Sodrazica';
import { Gaspinovo } from '../../objects/Gaspinovo';
import { WeatherData } from '../../objects/WeatherData';

class Station {
    constructor(ChartOptions, TableSettings) {
        this.ChartOptions = ChartOptions;
        this.TableSettings = TableSettings;
    }

    GetLabelFormatter() {
        let tool = {
            formatter: function() {
                var s = null;
                if(router.currentRoute._value.params.stationRange === 'daily') {
                    s = '<b>'+ moment(this.x).format('DD. MM YYYY HH:mm') +'</b>';
                }
                else {
                    s = '<b>'+ moment(this.x).format('DD. MM YYYY') +'</b>';
                }
        
                $.each(this.points, function(i, point) {
                        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
                });
        
                return s;
            },
            shared: true
        }
        return tool;
    }

    GetDailyFormatter() {
        let tool = {
            formatter: function() {
                var s = '<b>'+ moment(this.x).format('DD. MM. YYYY HH:mm') +'</b>';
        
                $.each(this.points, function(i, point) {
                        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
                });
        
                return s;
            },
            shared: true
        }
        return tool;
    }

    ChangeTimezone(response) {
        if (router.currentRoute.value.params.stationName != 'sodrazica' && router.currentRoute.value.params.stationId !== 'HkumAw2XoQs' && router.currentRoute.value.params.stationId !== 'Is6cqykhG1' && router.currentRoute.value.params.stationId !== '10s1ylZBdq0') {
            response.data.details.forEach(element => {
                element.date = moment(element.date).format('YYYY-MM-DD HH:mm:ss');
            });
        }
    }

    GetData(response, sodrazicaResponse) {
        var statsData = null;

        StationSettings.ChartSettings.Temperature.yAxis = this.ChartOptions.yAxis;
        StationSettings.ChartSettings.Temperature.xAxis = this.ChartOptions.xAxis;
        StationSettings.ChartSettings.Temperature.yAxis.title.text = 'Temperatura °C';

        StationSettings.ChartSettings.Temperature.xAxis.labels.formatter = function () {
            return moment(String(this.value)).format('HH:00');
        }

        StationSettings.ChartSettings.Temperature.tooltip = this.GetDailyFormatter();

        if (router.currentRoute._value.params.stationName === 'loski-potok') {
            var loskiPotok = new LoskiPotok();

            var originalArray = response.data.original.data;
            statsData = response.data.stats;

            loskiPotok.PrepareOriginal(originalArray);
            loskiPotok.PrepareStats(statsData);

            loskiPotok.AddChartData(StationSettings);
            loskiPotok.AddChartProperties(StationSettings);

            loskiPotok.AddTableData(StationSettings);

            loskiPotok.AddRecordData(StationSettings);

            loskiPotok.ValidateRecordData(StationSettings);
            
            loskiPotok.ValidateNullableData(loskiPotok);
        }
        
        if (router.currentRoute._value.params.stationName === 'sodrazica') {
            var izverSpodaj = new Sodrazica();
            var izverZgoraj = new Sodrazica();


            if (response.data.details == null || response.data.general == null) {
                console.log('no data')
            }
            else {
                var detailsArrayIzverSpodaj = response.data.details;
                var generalDataIzverSpodaj = response.data.general
                var statsDataIzverSpodaj = response.data.stats
                var unitsDataIzverSpodaj = response.data.units

                izverSpodaj.PrepareDetails(detailsArrayIzverSpodaj);
                izverSpodaj.PrepareGeneral(generalDataIzverSpodaj);
                izverSpodaj.PrepareStats(statsDataIzverSpodaj);
                izverSpodaj.PrepareUnits(unitsDataIzverSpodaj);
    
            }

            if (sodrazicaResponse.data.details == null || sodrazicaResponse.data.general == null) {
                console.log('no data')
            }
            else {

                var detailsArrayIzverZgoraj = sodrazicaResponse.data.details;
                var generalDataIzverZgoraj = sodrazicaResponse.data.general
                var statsDataIzverZgoraj = sodrazicaResponse.data.stats
                var unitsDataIzverZgoraj = sodrazicaResponse.data.units

                izverZgoraj.PrepareDetails(detailsArrayIzverZgoraj);
                izverZgoraj.PrepareGeneral(generalDataIzverZgoraj);
                izverZgoraj.PrepareStats(statsDataIzverZgoraj);
                izverZgoraj.PrepareUnits(unitsDataIzverZgoraj);
            }

            izverSpodaj.RemovedInvalidChartSeries(StationSettings);

            var sodrazica = izverSpodaj.AddChartData(izverSpodaj, izverZgoraj, StationSettings);

            izverSpodaj.AddChartProperties(StationSettings);

            izverSpodaj.AddTableData(StationSettings, sodrazica, izverZgoraj, izverSpodaj);

            izverSpodaj.ValidateNullableData(StationSettings, izverZgoraj, izverSpodaj);
        }

        if (router.currentRoute._value.params.stationName === 'ribnica') {
            var ribnica = new Ribnica();
            var gaspinovo = new Gaspinovo();

            originalArray = response.data.original.data;
            statsData = response.data.stats;

            if (sodrazicaResponse.data.details == null || sodrazicaResponse.data.general == null) {
                console.log('no data')
            }
            else {
                var detailsArrayGaspinovo = sodrazicaResponse.data.details;
                var generalDataGaspinovo = sodrazicaResponse.data.general
                var statsDataGaspinovo = sodrazicaResponse.data.stats
                var unitsDataGaspinovo = sodrazicaResponse.data.units
    
                gaspinovo.PrepareDetails(detailsArrayGaspinovo);
                gaspinovo.PrepareGeneral(generalDataGaspinovo);
                gaspinovo.PrepareStats(statsDataGaspinovo);
                gaspinovo.PrepareUnits(unitsDataGaspinovo);
    
            }

            var slemena = gaspinovo.AddChartData(StationSettings);

            ribnica.RemovedInvalidChartSeries(StationSettings);

            ribnica.PrepareOriginal(originalArray);
            ribnica.PrepareStats(statsData);

            var svAna = ribnica.AddChartData(StationSettings);
            ribnica.AddChartProperties(StationSettings);

            ribnica.AddTableData(StationSettings);

            ribnica.ValidateRecordData(StationSettings);
            
            if (StationSettings.ChartSettings.Temperature.series.length < 4) {
                StationSettings.ChartSettings.Temperature.series.push({ name: 'Gašpinovo', data: slemena.temp, color: '#0099FF' });
                StationSettings.ChartSettings.Temperature.series.push({ name: 'Sv. Ana', data: svAna , color: 'blue' });
            }
            
            gaspinovo.AddTableData(StationSettings);
            gaspinovo.ValidateNullableData(StationSettings);

            ribnica.AddSvAnaTableData(StationSettings);

            ribnica.AddRecordData(StationSettings, gaspinovo);

            ribnica.ValidateNullableData(ribnica);
        }
        return StationSettings;
    }

    GetDetailsData(response) {
        this.ChangeTimezone(response);
        console.log(response);

        if(SlemenaValidation(router.currentRoute._value.params) == true) {
            SlemenaDetails(response, StationSettings, this.ChartOptions, this.TableSettings)
        }
        else if (router.currentRoute.value.params.stationId == 'Is6cqykhG1') {
            SodrazicaDetails(response, StationSettings, this.ChartOptions, this.TableSettings);
        }
        else if (router.currentRoute.value.params.stationId == '10s1ylZBdq0') {
            SodrazicaDetails(response, StationSettings, this.ChartOptions, this.TableSettings);
        }
        else {
            var station = new WeatherData();
            var detailsArray = response.data.details;
            var statsData = response.data.stats;

            station.PrepareDetails(detailsArray);
            station.PrepareStats(statsData);

            var parameter = new Parameter();

            station.ClearDuplicatedChartSeries(StationSettings);
    
            station.FormatChartAxisTime(StationSettings);

            station.PrepareChartSeriesOptions(StationSettings);
    
            station.SetChartSeries(StationSettings);
            
            station.SetChartOptions(StationSettings, this.ChartOptions, router.currentRoute._value.params.stationRange);

            station.AddChartData(StationSettings, router.currentRoute._value.params.stationRange, router.currentRoute._value.params.stationId);

            StationSettings.DetailsSettings = this.TableSettings;

            parameter.GetStats(station.stats, StationSettings);
        }
        return StationSettings;
    }
}
export default Station;