import moment from "moment"

function AddDailyTimeSeries() {
    var dailySeries = [];
    var startTime = moment()
    .startOf("day")

    //const roundedUp = Math.ceil(moment().minute() / 5) * 5;
    //var endTime = moment().endOf('day').minute(roundedUp).second(0);
    var endTime = moment()
    .endOf('day');

    while(startTime <= endTime) {
        dailySeries.push(new moment(startTime));
        startTime.add(5, 'minutes');
    }
    return dailySeries;
}

function AddWeeklyTimeSeries() {
    //const roundedUp = Math.ceil(moment().minute() / 5) * 5;

    var weeklySeries = [];
    var startTime = moment()
    .startOf('hour')
    .subtract(168, 'hours')
    .add(30, 'minutes');

    //var endTime = moment().endOf('day').minute(roundedUp).second(0);
    var endTime = moment().endOf('day');

    while(startTime <= endTime) {
        weeklySeries.push(new moment(startTime));
        startTime.add(30, 'minutes');
    }

    return weeklySeries;
}

function AddMonthlyTimeSeries() {
    var monthlySeries = [];
    var startTime = moment()
    .startOf("month")

    //const roundedUp = Math.ceil(moment().minute() / 5) * 5;
    //var endTime = moment().endOf('day').minute(roundedUp).second(0);
    var endTime = moment().endOf('day');

    while(startTime <= endTime) {
        monthlySeries.push(new moment(startTime));
        startTime.add(60, 'minutes');
    }
    return monthlySeries;
}

export {
    AddDailyTimeSeries,
    AddWeeklyTimeSeries,
    AddMonthlyTimeSeries
}