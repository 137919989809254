import moment from "moment";

export const ChartOptions = {
        title: {
            text: ''
        },
        xAxis: {
            type: 'datetime',
            categories: [],
            labels: {
                formatter: function () {
                    return moment(String(this.value)).format('HH:mm');
                }
            },
            tickInterval: 40,
            gridLineColor: 'black',
            gridLineWidth: 0.3,
        },
        plotOptions: {
            series: {
                connectNulls: false
            },
            area: {
                backgroundColor: 'gray'
            }
        },
        series: [{}],
        responsive: {
            rules: [
                {
                    condition: {
                        maxWidth: 500,
                    },
                    chartOptions: {
                        legend: {
                            enabled: false,
                        },
                    },
                },
            ],
        },
        yAxis: {
            title: {
                text: ''
            },
            gridLineColor: 'black',
            gridLineWidth: 0.3,
            tickPositioner: function () {
                var positions = [],
                    tick = Math.floor(this.dataMin),
                    increment = Math.ceil((this.dataMax - this.dataMin) / 12);

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }
                return positions;
            }
        },
}

export default ChartOptions;