import moment from "moment";
import { CalculateRain } from "../Fix/CalculateRain";

class Parameter {
  GetStats(stats, StationSettings) {
    console.log(stats)
    var data = StationSettings.DetailsSettings;
    data.temperature.value = stats.currentTemperature;
    data.temperature.maxValue = stats.maxTemperature;
    data.temperature.maxTime = moment(stats.maxTemperatureDate).format("HH:mm (DD. MM.)");
    data.temperature.minValue = stats.minTemperature;
    data.temperature.minTime = moment(stats.minTemperatureDate).format("HH:mm (DD. MM.)");
    data.temperature.avgValue = stats.avgTemperature;

    data.humidity.value = stats.currentHumidity;
    data.humidity.maxValue = stats.maxHumidity;
    data.humidity.maxTime = moment(stats.maxHumidityDate).format("HH:mm (DD. MM.)");
    data.humidity.minValue = stats.minHumidity;
    data.humidity.minTime = moment(stats.minHumidityDate).format("HH:mm (DD. MM.)");
    data.humidity.avgValue = stats.avgHumidity;

    data.dewpoint.value = stats.currentDewpoint;
    data.dewpoint.maxValue = stats.maxDewpoint;
    data.dewpoint.maxTime = moment(stats.maxDewpointDate).format("HH:mm (DD. MM.)");
    data.dewpoint.minValue = stats.minDewpoint;
    data.dewpoint.minTime = moment(stats.minDewpointDate).format("HH:mm (DD. MM.)");
    data.dewpoint.avgValue = stats.avgDewpoint;

    data.pressure.value = stats.currentPressure;
    data.pressure.maxValue = stats.maxPressure;
    data.pressure.maxTime = moment(stats.maxPressureDate).format("HH:mm (DD. MM.)");
    data.pressure.minValue = stats.minPressure;
    data.pressure.minTime = moment(stats.minPressureDate).format("HH:mm (DD. MM.)");
    data.pressure.avgValue = stats.avgPressure;

    data.uv.value = stats.currentSolarRadiation;
    data.uv.maxValue = stats.maxSolarRadiation;
    data.uv.maxTime = moment(stats.maxSolarRadiationDate).format("HH:mm (DD. MM.)");
    data.uv.minValue = stats.minSolarRadiation;
    data.uv.minTime = moment(stats.minSolarRadiationDate).format("HH:mm (DD. MM.)");
    data.avgValue = null;

    var rainDetails = CalculateRain(stats);
    data.rain.value = rainDetails.rainType;
    data.rain.minValue = rainDetails.lastHourRain;
    data.rain.maxValue = '---';

    data.windSpeed.value = stats.currentWindSpeed;
    data.windSpeed.maxValue = stats.avgWindSpeed;
    data.windSpeed.maxTime = null;
    data.windSpeed.minValue = stats.maxWindGust;
    data.windSpeed.minTime = moment(stats.maxWindGustDate).format("HH:mm (DD. MM.)");
    return data;
  }

  GetLatest(data, details) {
    if (details.length == 0) {
      return;
    }

    let tempArr = details.map(x => x.temperature);
    let humArr = details.map(x => x.humidity);
    let dewArr = details.map(x => x.dewpoint);
    let pressureArr = details.map(x => x.pressure);
    let uvArr = details.map(x => x.solarRadiation);
    let rainArr = details.map(x => x.rain);
    let windArr = details.map(x => x.windSpeed);

    let tempIndex = tempArr.length;

    while (tempIndex-- && !tempArr[tempIndex]);
    
    if (tempArr[tempIndex] == undefined) {
        data.temperature.value = '---';
        data.humidity.value = '---';
        data.dewpoint.value = '---';
        data.pressure.value = '---';
        data.uv.value = '---';
        return;
    }

    if(uvArr[tempIndex] == 0) {
      data.uv.value = 0;
    }
    
    if (data.temperature.value == null) {
      data.temperature.value = tempArr[tempIndex];
    }

    if (data.humidity.value == null) {
      data.humidity.value = humArr[tempIndex];
    }

    if (data.dewpoint.value == null) {
      data.dewpoint.value = dewArr[tempIndex];
    }

    if (data.pressure.value == null) {
      data.pressure.value = pressureArr[tempIndex];
    }

    if (data.uv.value == null) {
      data.uv.value == uvArr[tempIndex];
    }

    if (data.rain.value == null) {
      data.rain.value = rainArr[tempIndex];
    }

    if (data.windSpeed.value == null) {
      data.windSpeed.value = windArr[tempIndex];
    }
  }
}

export default Parameter;
