function CalculateRain(stats) {
    var rainDetails = { lastHourRain: null, rainType: null }

    if (stats.accumulatedRain != null) {
        rainDetails.lastHourRain = stats.lastHourRain;
        rainDetails.rainType = stats.accumulatedRain;
    }

    if (stats.currentRain != null && stats.dailyRain != null) {
        rainDetails.lastHourRain = stats.lastHourRain;
        rainDetails.rainType = stats.dailyRain;
    }
    else if (stats.dailyRain != null) {
        rainDetails.lastHourRain = stats.lastHourRain;
        rainDetails.rainType = stats.dailyRain;
    }
    else if (stats.weeklyRain != null) {
        rainDetails.lastHourRain = stats.lastHourRain;
        rainDetails.rainType = stats.weeklyRain;
    }
    else if (stats.monthlyRain != null) {
        rainDetails.lastHourRain = stats.lastHourRain;
        rainDetails.rainType = stats.monthlyRain;
    }
    else {
        rainDetails.lastHourRain = stats.lastHourRain;
    }
    return rainDetails
}

export {
    CalculateRain
}