import moment from "moment";
import stationList from "../StationList/StationList";

function GetCurrentStationsData(responses) {
    var currentMeteo = responses[0].data.stats
    var currentSlemena = responses[3].data;
    stationList.firstRow[0].temp = currentMeteo.ribnicaCurrentTemperature;
    stationList.firstRow[0].hum = currentMeteo.ribnicaCurrentHumidity;
    stationList.firstRow[0].refreshDate = moment(currentMeteo.ribnicaCurrentDateTime).format('DD. MM. YYYY HH:mm'); 

    stationList.firstRow[1].temp = currentMeteo.prigoricaCurrentTemperature;
    stationList.firstRow[1].hum = currentMeteo.prigoricaCurrentHumidity;
    stationList.firstRow[1].refreshDate = moment(currentMeteo.prigoricaCurrentDateTime).format('DD. MM. YYYY HH:mm'); 

    stationList.firstRow[2].temp = currentSlemena.temperature;
    stationList.firstRow[2].hum = currentSlemena.humidity;
    stationList.firstRow[2].refreshDate = moment(currentSlemena.updated).format('DD. MM. YYYY HH:mm');

    stationList.secondRow[0].temp = currentMeteo.svAnaCurrentTemperature;
    stationList.secondRow[0].hum = currentMeteo.svAnaCurrentHumidity;
    stationList.secondRow[0].refreshDate = moment(currentMeteo.svAnaCurrentDateTime).format('DD. MM. YYYY HH:mm');

    stationList.secondRow[1].temp = currentMeteo.hribCurrentTemperature;
    stationList.secondRow[1].hum = currentMeteo.hribCurrentHumidity;
    stationList.secondRow[1].refreshDate = moment(currentMeteo.hribCurrentDateTime).format('DD. MM. YYYY HH:mm');

    stationList.secondRow[2].temp = currentMeteo.retjeCurrentTemperature;
    stationList.secondRow[2].hum = currentMeteo.retjeCurrentHumidity;
    stationList.secondRow[2].refreshDate = moment(currentMeteo.retjeCurrentDateTime).format('DD. MM. YYYY HH:mm');

    return stationList;
}

export {
    GetCurrentStationsData
}