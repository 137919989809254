import moment from 'moment';
import { LatestAvailable } from '../Utils/Fix/LatestAvailable';
//import { FillChartData } from '../Utils/Fix/FillChartData';
import { AddDailyTimeSeries } from '../Utils/Stats/Time/AddTimeSeries';

class Gaspinovo {
    constructor() {
        this.details = [];
        this.stats = {};
    }

    PrepareDetails(detailsArray) {
        detailsArray.forEach(x => {
            var details = new Details(
                x.temperature,
                x.dewpoint,
                x.humidity,
                x.windSpeed,
                x.windGust,
                x.windDirection,
                x.dailyRain,
                x.solarRadiation,
                x.dateTime
            )
            this.details.push(details);
        });
    }

    PrepareGeneral(generalData) {
        var general = new General(
            generalData.altitude,
            generalData.stationType,
            generalData.unit
        )
        this.general = general;
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.currentTemperature,
            statsData.currentHumidity,
            statsData.currentWindSpeed,
            statsData.currentDewpoint,
            statsData.currentSolarRadiation,
            statsData.accumulatedRain,
            statsData.lastUpdated,
            statsData.avgDewpoint,
            statsData.avgHumidity,
            statsData.avgTemperature,
            statsData.avgWindSpeed,
            statsData.lastHourRain,
            statsData.maxDewpoint,
            statsData.maxDewpointDate,
            statsData.maxHumidity,
            statsData.maxHumidityDate,
            statsData.maxSolarRadiation,
            statsData.maxSolarRadiationDate,
            statsData.minSolarRadiation,
            statsData.minSolarRadiationDate,
            statsData.maxTemperature,
            statsData.maxTemperatureDate,
            statsData.maxWindGust,
            statsData.maxWindGustDate,
            statsData.minDewpoint,
            statsData.minDewpointDate,
            statsData.minHumidity,
            statsData.minHumidityDate,
            statsData.minTemperature,
            statsData.minTemperatureDate
        )
        this.stats = stats;
    }

    PrepareUnits(unitsData) {
        var units = new Units(
            unitsData.dewpointUnit,
            unitsData.humidityUnit,
            unitsData.pressureUnit,
            unitsData.rainUnit,
            unitsData.solarObservationUnit,
            unitsData.temperatureUnit,
            unitsData.windUnit
        )
        this.units = units;
    }

    AddChartData(StationSettings) {
        var slemena = { temp: [], date: [], hum: [] };

        //FillChartData(this.details, AddDailyTimeSeries());

        slemena.temp = this.details.map(x => x.temperature);
        slemena.hum = this.details.map(x => x.humidity);
        slemena.date = this.details.map(x => moment(x.updated));

        StationSettings.ChartSettings.Temperature.xAxis.categories = AddDailyTimeSeries();
        
        return slemena;
    }

    AddDetailsChartData(StationSettings) {
        //FillChartData(this.details, AddDailyTimeSeries());

        StationSettings.ChartSettings.Temp.series[0].data = this.details.map((x) => x.temperature);

        StationSettings.ChartSettings.Humidity.series[0].data = this.details.map((x) => x.humidity);
    
        StationSettings.ChartSettings.Rain.series[0].data = this.details.map((x) => x.rainTotal);
    
        StationSettings.ChartSettings.Wind.series[0].data = this.details.map(x => x.windGust);
        StationSettings.ChartSettings.Wind.series[1].data = this.details.map(x => x.windSpeed);
    
        StationSettings.ChartSettings.Dewpoint.series[0].data = this.details.map((x) => x.dewpoint);
    
        StationSettings.ChartSettings.UV.series[0].data = this.details.map((x) => x.solarRadiation);

        StationSettings.ChartSettings.UV.xAxis.categories = AddDailyTimeSeries();

        StationSettings.LastRefresh = moment(this.details[this.details.length-1].updated).format('DD. MM. Y HH:mm');
    }

    AddChartProperties(StationSettings) {
        StationSettings.ChartSettings.Temperature.series[0].name = 'Izver spodaj';
        StationSettings.ChartSettings.Temperature.series[0].color = '#00BFFF';
        StationSettings.ChartSettings.Temperature.series[1].name = 'Izver na vrhu';
        StationSettings.ChartSettings.Temperature.series[1].color = '#00008B';
    }

    AddTableData(StationSettings) {
        StationSettings.TableSettings.Ribnica[2].temp = this.stats.currentTemperature;
        StationSettings.TableSettings.Ribnica[2].date = moment(this.stats.lastUpdated).format('DD. MM. Y HH:mm');
        StationSettings.TableSettings.Ribnica[2].hum = this.stats.currentHumidity;
    }


    ValidateNullableData(StationSettings) {        
        LatestAvailable(StationSettings, this.details);
    }
}

class Details {
    constructor(temperature, dewpoint, humidity, windSpeed, windGust, windDirection, rainTotal, solarRadiation, updated) {
        this.temperature = temperature;
        this.dewpoint = dewpoint;
        this.humidity = humidity;
        this.windSpeed = windSpeed;
        this.windGust = windGust;
        this.windDirection = windDirection;
        this.rainTotal = rainTotal;
        this.solarRadiation = solarRadiation;
        this.updated = updated;
    }
}

class General {
    constructor(altitude, stationType, unit) {
        this.altitude = altitude;
        this.stationType = stationType;
        this.unit = unit;
    }
}

class Stats {
    constructor(
        currentTemperature,
        currentHumidity,
        currentWindSpeed,
        currentDewpoint,
        currentSolarRadiation,
        accumulatedRain,
        lastUpdated,
        avgDewpoint,
        avgHumidity,
        avgTemperature,
        avgWindSpeed,
        lastHourRain,
        maxDewpoint,
        maxDewpointDate,
        maxHumidity,
        maxHumidityDate,
        maxSolarRadiation,
        maxSolarRadiationDate,
        minSolarRadiation,
        minSolarRadiationDate,
        maxTemperature,
        maxTemperatureDate,
        maxWindGust,
        maxWindGustDate,
        minDewpoint,
        minDewpointDate,
        minHumidity,
        minHumidityDate,
        minTemperature,
        minTemperatureDate
    ) 
    {
        this.currentTemperature = currentTemperature;
        this.currentHumidity = currentHumidity;
        this.currentWindSpeed = currentWindSpeed;
        this.currentDewpoint = currentDewpoint;
        this.currentSolarRadiation = currentSolarRadiation;
        this.accumulatedRain = accumulatedRain;
        this.lastUpdated = lastUpdated;
        this.avgDewpoint = avgDewpoint;
        this.avgHumidity = avgHumidity;
        this.avgTemperature = avgTemperature;
        this.avgWindSpeed = avgWindSpeed;
        this.lastHourRain = lastHourRain;
        this.maxDewpoint = maxDewpoint;
        this.maxDewpointDate = maxDewpointDate;
        this.maxHumidity = maxHumidity;
        this.maxHumidityDate = maxHumidityDate;
        this.maxSolarRadiation = maxSolarRadiation;
        this.maxSolarRadiationDate = maxSolarRadiationDate;
        this.minSolarRadiation = minSolarRadiation;
        this.minSolarRadiationDate = minSolarRadiationDate;
        this.maxTemperature = maxTemperature;
        this.maxTemperatureDate = maxTemperatureDate;
        this.maxWindGust = maxWindGust;
        this.maxWindGustDate = maxWindGustDate;
        this.minDewpoint = minDewpoint;
        this.minDewpointDate = minDewpointDate;
        this.minHumidity = minHumidity;
        this.minHumidityDate = minHumidityDate;
        this.minTemperature = minTemperature;
        this.minTemperatureDate = minTemperatureDate;
    }
}

class Units {
    constructor(dewpointUnit, humidityUnit, pressureUnit, rainUnit, solarObservationUnit, temperatureUnit, windUnit) {
        this.dewpointUnit = dewpointUnit;
        this.humidityUnit = humidityUnit;
        this.pressureUnit = pressureUnit;
        this.rainUnit = rainUnit;
        this.solarObservationUnit = solarObservationUnit;
        this.temperatureUnit = temperatureUnit;
        this.windUnit = windUnit;
    }
}

export {
    Gaspinovo,
    Details,
    General,
    Stats,
    Units
}