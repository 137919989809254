import Clear from "../../Stats/Clear/Clear";
import moment from 'moment';
import $ from 'jquery';
import { Gaspinovo } from "../../../objects/Gaspinovo";
import Parameter from "../Parameter";

function SlemenaValidation(params) {
    let isSlemena = false;
    if (params.stationId === 'HkumAw2XoQs') {
        isSlemena = true;
    }
    return isSlemena;
}

function GetDailyFormatter() {
    let tool = {
        formatter: function() {
            var s = '<b>'+ moment(this.x).format('DD. MM. YYYY HH:mm') +'</b>';
    
            $.each(this.points, function(i, point) {
                    s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
            });
    
            return s;
        },
        shared: true
    }
    return tool;
}

function SlemenaDetails(response, StationSettings, ChartOptions, TableSettings) {
    var parameter = new Parameter(); 
    var gaspinovo = new Gaspinovo()

    Clear(StationSettings.ChartSettings.Temp)
    Clear(StationSettings.ChartSettings.Humidity)
    Clear(StationSettings.ChartSettings.Pressure)
    Clear(StationSettings.ChartSettings.Rain)
    Clear(StationSettings.ChartSettings.Wind)
    Clear(StationSettings.ChartSettings.Dewpoint)

    StationSettings.ChartSettings.Temp.series.push({ data: [], name: 'Temperatura', color: 'red' });
    StationSettings.ChartSettings.Humidity.series.push({ data: [], name: 'Vlaga', color: 'green' });
    StationSettings.ChartSettings.Rain.series.push({ data: [], name: 'Padavine', color: 'blue' });
    StationSettings.ChartSettings.Wind.series.push({ data: [], name: 'Sunki vetra', color: 'black' }, { data: [], name: 'Hitrost vetra', color: 'gray' });
    StationSettings.ChartSettings.Dewpoint.series.push({ data: [], name: 'Rosišče', color: 'orange' });

    StationSettings.ChartSettings.Temp.series.length = 1;
    StationSettings.ChartSettings.Humidity.series.length = 1;
    StationSettings.ChartSettings.Rain.series.length = 1;
    StationSettings.ChartSettings.Wind.series.length = 2;
    StationSettings.ChartSettings.Dewpoint.series.length = 1;

    StationSettings.ChartSettings.Temp.yAxis.tickPositioner = ChartOptions.yAxis.tickPositioner;
    StationSettings.ChartSettings.Temp.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Humidity.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Rain.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Wind.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.Dewpoint.xAxis = ChartOptions.xAxis;
    StationSettings.ChartSettings.UV.xAxis = ChartOptions.xAxis;
    
    var detailsArrayGaspinovo = response.data.details;
    var generalDataGaspinovo = response.data.general;
    var statsDataGaspinovo = response.data.stats;
    var unitsDataGaspinovo = response.data.units;

    gaspinovo.PrepareDetails(detailsArrayGaspinovo);
    gaspinovo.PrepareGeneral(generalDataGaspinovo);
    gaspinovo.PrepareStats(statsDataGaspinovo);
    gaspinovo.PrepareUnits(unitsDataGaspinovo);

    gaspinovo.AddDetailsChartData(StationSettings);

    gaspinovo.AddTableData(StationSettings);
    gaspinovo.ValidateNullableData(StationSettings);

    StationSettings.ChartSettings.Temp.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Humidity.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Rain.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Wind.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.Dewpoint.tooltip = GetDailyFormatter();
    StationSettings.ChartSettings.UV.tooltip = GetDailyFormatter();

    StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
        return moment(String(this.value)).format('HH:mm');
    }

    StationSettings.DetailsSettings = TableSettings;
    let data = parameter.GetStats(gaspinovo.stats, StationSettings);
    parameter.GetLatest(data, gaspinovo.details);

}

export {
    SlemenaValidation,
    SlemenaDetails
}