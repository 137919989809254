import { createStore } from 'vuex'
import axios from 'axios'
import { ChartOptions } from '../Utils/Chart/ChartOptions';
import { GetConfig } from '../Utils/Axios/AxiosOptions';
import Station from '../Utils/Station/Station';
import { TableSettings } from '../Data/Station/TableSettings';
import { Settings, InitialChartSettings } from '../Data/Data';
import AxiosUrl from '../Utils/Axios/AxiosUrl';
import router from '../router/index'
import { stationIDs } from '../Utils/Station/StationID';
import { getWithExpiry } from '../Utils/LocalStorage/LocalStorage';
import moment from 'moment'
import { GetCurrentStationsData } from '../Utils/Station/CurrentStation';

export default createStore({
  state: {
    chartOptions: [],
    stationList: [],
    detailsChartOptions: [],
    detailsStationList: [],
    humidityChartOptions: [],
    pressureChartOptions: [],
    rainChartOptions: [],
    windChartOptions: [],
    dewpointChartOptions: [],
    uvChartOptions: [],
    refreshTime: {},
    recordsDayData: [],
    stations: {},
    titleDate: null
  },
  mutations: {
    SET_CHART_OPTIONS(state, chartOptions) {
      state.chartOptions = chartOptions
    },
    SET_TABLE_DATA(state, stationList) {
      state.stationList = stationList;
    },
    SET_DETAILS_CHART_OPTIONS(state, detailsChartOptions) {
      state.detailsChartOptions = detailsChartOptions
    },
    SET_HUMIDITY_CHART_OPTIONS(state, humidityChartOptions) {
      state.humidityChartOptions = humidityChartOptions;
    },
    SET_PRESSURE_CHART_OPTIONS(state, pressureChartOptions) {
      state.pressureChartOptions = pressureChartOptions;
    },
    SET_RAIN_CHART_OPTIONS(state, rainChartOptions) {
      state.rainChartOptions = rainChartOptions;
    },
    SET_WIND_CHART_OPTIONS(state, windChartOptions) {
      state.windChartOptions = windChartOptions;
    },
    SET_DEWPOINT_CHART_OPTIONS(state, dewpointChartOptions) {
      state.dewpointChartOptions = dewpointChartOptions;
    },
    SET_UV_CHART_OPTIONS(state, uvChartOptions) {
      state.uvChartOptions = uvChartOptions;
    },
    SET_DETAILS_TABLE_DATA(state, detailsStationList) {
      state.detailsStationList = detailsStationList;
    },
    SET_REFRESH_TIME(state, refreshTime) {
      state.refreshTime = refreshTime;
    },
    SET_RECORDS_DAY_DATA(state, recordsDayData) {
      state.recordsDayData = recordsDayData;
    },
    SET_STATIONS_DAY_DATA(state, stations) {
      state.stations = stations;
    },
    SET_SODRAZICA_TITLE_DATE(state, titleDate) {
      state.titleDate = titleDate;
    }
  },
  actions: {
    getWidgetData({ commit }) {
      var params = router.currentRoute._value.params;
      var fullPath = router.currentRoute.value.fullPath;
      var config = null;
      var configSlemena = null;
      var configSodrazica = null;
      var configArr = [];
      var configLoski = null;
      var configIzverSpodaj = null;
      var configIzverZgoraj = null;

      if(params.stationName === 'loski-potok') {
        config = GetConfig(AxiosUrl.LoskiPotok.Daily.Url);
      }
      else if (params.stationName === 'ribnica') {
        config = GetConfig(AxiosUrl.Ribnica.Daily.Url);
      }
      else if (params.stationName === 'sodrazica') {
        config = GetConfig(AxiosUrl.Sodrazica.Izver_Zgoraj.Daily.Url);
      }
      else if (fullPath === '/stations') {
        config = GetConfig(AxiosUrl.Preview.CurrentMeteo.Url);
      }

      const requestOne = axios(config);
      if (params.stationName === 'loski-potok') {
        configArr.push(requestOne);
      }
      else if (params.stationName === 'sodrazica') {
        configArr.push(requestOne);

        if (getWithExpiry('sodrazica') == null) {
          console.log('ni podatkov, sodrazica api')
          configSodrazica = GetConfig(AxiosUrl.Sodrazica.Izver_Spodaj.Daily.Url);
          const requestTwo = axios(configSodrazica);
          configArr.push(requestTwo); 
        }
        else {
          console.log('Ne rabimo sodrazica apija')
        }
      }
      else if (params.stationName === 'ribnica') {
        configArr.push(requestOne);

        if (getWithExpiry('slemena') == null) {
          console.log('ni podatkov, zazenbemo api')
          configSlemena = GetConfig(AxiosUrl.Ribnica.DailySlemena.Url);
          const requestTwo = axios(configSlemena);
          configArr.push(requestTwo);
        }
        else {
          console.log('Ne rabimo zagnati aipja')
        }

      }
      else if (fullPath === '/stations') {
        configArr.push(requestOne);
        
        configIzverSpodaj = GetConfig(AxiosUrl.Preview.CurrentIzverSpodaj.Url);
        const requestTwo = axios(configIzverSpodaj);
        configArr.push(requestTwo);

        configIzverZgoraj = GetConfig(AxiosUrl.Preview.CurrentIzverZgoraj.Url);
        const requestThree = axios(configIzverZgoraj);
        configArr.push(requestThree);

        configLoski = GetConfig(AxiosUrl.Preview.CurrentSlemena.Url);
        const requestFour = axios(configLoski);
        configArr.push(requestFour);
      }

      axios
        .all(configArr)
        .then(axios.spread((...responses) => {
          const loskiPotok = new Station(ChartOptions, TableSettings);
          var stationSettings = loskiPotok.GetData(responses[0], responses[1]);

          var recordsData = stationSettings.RecordSettings;

          commit('SET_CHART_OPTIONS', stationSettings.ChartSettings.Temperature);
          if(params.stationName === 'loski-potok') {
            commit('SET_TABLE_DATA', stationSettings.TableSettings.LoskiPotok);
            commit('SET_RECORDS_DAY_DATA', recordsData);
          }
          else if (params.stationName === 'sodrazica') {
            commit('SET_TABLE_DATA', stationSettings.TableSettings.Sodrazica);
            commit('SET_RECORDS_DAY_DATA', null);
            commit('SET_SODRAZICA_TITLE_DATE', moment(stationSettings.TableSettings.Sodrazica[0].date).format('DD. MM. ob HH:mm'));
          }
          else if (params.stationName === 'ribnica') {
            let date = stationSettings.TableSettings.Ribnica[3].date.substring(13, 15);
            var svAnaTime = parseInt(date);
            var now = moment().hours();
            if (now - svAnaTime >= 2) {
              stationSettings.TableSettings.Ribnica[3].isLate = false; 
            }
            commit('SET_TABLE_DATA', stationSettings.TableSettings.Ribnica);
            commit('SET_RECORDS_DAY_DATA', recordsData);
          }
          else if (router.currentRoute.value.fullPath === '/stations') {

            var stationList = GetCurrentStationsData(responses);
            commit('SET_STATIONS_DAY_DATA', stationList);
          }
        }))
        .catch(error => {
          console.log(error)
        })
        .finally(() => {

        })
    },
    getDetailsData({ commit }) {
      //TODO in another method
      var params = router.currentRoute._value.params;
      var config = null;
      var StationIDs = null;
      var stationSettings = null;
      let configArr = [];
      let endpoint = null

      if(params.stationName === 'loski-potok') {
        endpoint = AxiosUrl.LoskiPotokDetails;
      }
      else if (params.stationName === 'ribnica') {
        endpoint = AxiosUrl.RibnicaDetails;
      }
      else if (params.stationName === 'sodrazica') {
        endpoint = AxiosUrl.Sodrazica;
      }

      if(params.stationId === 'LYk4p9r8RA') {
        config = GetConfig(endpoint.Hrib.Daily.Url)
        StationIDs = stationIDs[0];
        if(params.stationRange === 'monthly') {
          config = GetConfig(endpoint.Hrib.Monthly.Url)
        }
        else if (params.stationRange === 'weekly') {
          config = GetConfig(endpoint.Hrib.Weekly.Url);
        }
      }
      else if(params.stationId === 'NxEkuvG2VT') {
        config = GetConfig(endpoint.Retje.Daily.Url);
        StationIDs = stationIDs[1];
        if(params.stationRange === 'monthly') {
          config = GetConfig(endpoint.Retje.Monthly.Url);
        }
        else if (params.stationRange === 'weekly') {
          config = GetConfig(endpoint.Retje.Weekly.Url);
        }
      }
      else if(params.stationId === 'Cy3kas34kV') {
        StationIDs = stationIDs[3];
        config = GetConfig(endpoint.Ribnica.Daily.Url)
        if(params.stationRange === 'monthly') {
          config = GetConfig(endpoint.Ribnica.Monthly.Url);
        }
        else if (params.stationRange === 'weekly') {
          config = GetConfig(endpoint.Ribnica.Weekly.Url);
        }
      }
      else if(params.stationId === 'KMn2x1KsWgQ') {
        config = GetConfig(endpoint.Prigorica.Daily.Url);
        StationIDs = stationIDs[2];
        if(params.stationRange === 'monthly') {
          config = GetConfig(endpoint.Prigorica.Monthly.Url);
        }
        else if (params.stationRange === 'weekly') {
          config  = GetConfig(endpoint.Prigorica.Weekly.Url);
        }
      }
      else if(params.stationId === 'Rds23w5XoyD') {
        config = GetConfig(endpoint.SvAna.Daily.Url);
        StationIDs = stationIDs[4];
        if(params.stationRange === 'monthly') {
          config = GetConfig(endpoint.SvAna.Monthly.Url);
        }
        else if (params.stationRange === 'weekly') {
          config = GetConfig(endpoint.SvAna.Weekly.Url);
        }
      }
      else if (params.stationId === 'Is6cqykhG1') {
        config = GetConfig(endpoint.Izver_Spodaj.Daily.Url);
      }
      else if (params.stationId === '10s1ylZBdq0') {
        config = GetConfig(endpoint.Izver_Zgoraj.Daily.Url);
      }
      else if(params.stationId === 'HkumAw2XoQs') {
        config = GetConfig(endpoint.Slemena.Daily.Url)
      }

      const requestOne = axios(config);
      configArr.push(requestOne);

      axios
        .all(configArr)
        .then(axios.spread((...responses) => {
          const loskiPotok = new Station(ChartOptions, Settings);

          if(params.stationRange === 'daily' || params.stationRange === 'weekly' || params.stationRange == 'monthly') {
            stationSettings = loskiPotok.GetDetailsData(responses[0], StationIDs);
          }
          else if (params.stationRange === 'monthly') {
            stationSettings = loskiPotok.GetDetailsMonthlyData(responses[0], StationIDs);
          }
          else if (params.stationRange === 'yearly') {
            stationSettings = loskiPotok.GetDetailsYearlyData(responses[0], StationIDs, responses[1]);
          }

          commit('SET_DETAILS_CHART_OPTIONS', stationSettings.ChartSettings.Temp);
          commit('SET_HUMIDITY_CHART_OPTIONS', stationSettings.ChartSettings.Humidity)
          commit('SET_PRESSURE_CHART_OPTIONS', stationSettings.ChartSettings.Pressure)
          commit('SET_RAIN_CHART_OPTIONS', stationSettings.ChartSettings.Rain)
          commit('SET_WIND_CHART_OPTIONS', stationSettings.ChartSettings.Wind);
          commit('SET_DEWPOINT_CHART_OPTIONS', stationSettings.ChartSettings.Dewpoint)
          commit('SET_UV_CHART_OPTIONS', stationSettings.ChartSettings.UV)
          commit('SET_REFRESH_TIME', stationSettings.LastRefresh)
        }))
        .catch((error) => {
          commit('SET_DETAILS_CHART_OPTIONS', InitialChartSettings.Temp);
          commit('SET_HUMIDITY_CHART_OPTIONS', InitialChartSettings.Humidity)
          commit('SET_PRESSURE_CHART_OPTIONS', InitialChartSettings.Pressure)
          commit('SET_RAIN_CHART_OPTIONS', InitialChartSettings.Rain)
          commit('SET_WIND_CHART_OPTIONS', InitialChartSettings.Wind);
          commit('SET_DEWPOINT_CHART_OPTIONS', InitialChartSettings.Dewpoint)
          commit('SET_UV_CHART_OPTIONS', InitialChartSettings.UV)
          commit('SET_DETAILS_TABLE_DATA', []);
          console.log(error);
        })
        .finally(() => {
          
        }) 
    }         
  },
  getters: {
    chartOptions: (state) => state.chartOptions,
    stationList: (state) => state.stationList,
    detailsChartOptions: (state) => state.detailsChartOptions,
    detailsStationList: (state) => state.detailsStationList,
    humidityChartOptions: (state) => state.humidityChartOptions,
    pressureChartOptions: (state) => state.pressureChartOptions,
    rainChartOptions: (state) => state.rainChartOptions,
    windChartOptions: (state) => state.windChartOptions,
    dewpointChartOptions: (state) => state.dewpointChartOptions,
    uvChartOptions: (state) => state.uvChartOptions,
    refreshTime: (state) => state.refreshTime,
    recordsDayData: (state) => state.recordsDayData,
    stationsData: (state) => state.stations,
    getSodrazicaTitleDate: (state) => state.titleDate
  },
  modules: {
  }
})
