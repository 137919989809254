import moment from "moment";
import { AddDailyTimeSeries, AddMonthlyTimeSeries, AddWeeklyTimeSeries } from "../Utils/Stats/Time/AddTimeSeries";
import $ from 'jquery';
import Clear from "../Utils/Stats/Clear/Clear";

class WeatherData {
    constructor() {
        this.details = [];
        this.stats = {};
    }

    PrepareDetails(detailsArray) {
        detailsArray.forEach(x => {
            var detail = new Details(
                x.temperature,
                x.humidity,
                x.dewpoint,
                x.windSpeed,
                x.windGust,
                x.windDirection,
                x.pressure,
                x.rain,
                x.dailyRain,
                x.weeklyRain,
                x.monthlyRain,
                x.solarRadiation,
                x.dateTime
            )
            this.details.push(detail);
        });
    }

    PrepareStats(statsData) {
        var stats = new Stats(
            statsData.currentTemperature,
            statsData.currentHumidity,
            statsData.currentWindSpeed,
            statsData.currentPressure,
            statsData.currentDewpoint,
            statsData.currentSolarRadiation,
            statsData.currentRain,
            statsData.lastUpdated,
            statsData.maxTemperature,
            statsData.maxTemperatureDate,
            statsData.minTemperature,
            statsData.minTemperatureDate,
            statsData.maxHumidity,
            statsData.maxHumidityDate,
            statsData.minHumidity,
            statsData.minHumidityDate,
            statsData.maxDewpoint,
            statsData.maxDewpointDate,
            statsData.minDewpoint,
            statsData.minDewpointDate,
            statsData.lastHourRain,
            statsData.accumulatedRain,
            statsData.dailyRain,
            statsData.weeklyRain,
            statsData.monthlyRain,
            statsData.maxWindGust,
            statsData.maxWindGustDate,
            statsData.maxPressure,
            statsData.maxPressureDate,
            statsData.minPressure,
            statsData.minPressureDate,
            statsData.maxSolarRadiation,
            statsData.maxSolarRadiationDate,
            statsData.minSolarRadiation,
            statsData.minSolarRadiationDate,
            statsData.avgTemperature,
            statsData.avgHumidity,
            statsData.avgDewpoint,
            statsData.avgPressure,
            statsData.avgSolarRadiation,
            statsData.avgWindSpeed
        )
        this.stats = stats;
    }

    ClearDuplicatedChartSeries(StationSettings) {
        Clear(StationSettings.ChartSettings.Temp)
        Clear(StationSettings.ChartSettings.Humidity)
        Clear(StationSettings.ChartSettings.Pressure)
        Clear(StationSettings.ChartSettings.Rain)
        Clear(StationSettings.ChartSettings.Wind)
        Clear(StationSettings.ChartSettings.Dewpoint)
    }

    FormatChartAxisTime(StationSettings) {
        StationSettings.ChartSettings.Dewpoint.xAxis.labels.formatter = function () {
            return moment(String(this.value)).format('HH:00');  
        }
    }

    PrepareChartSeriesOptions(StationSettings) {
        StationSettings.ChartSettings.Temp.series.push({ data: [], name: 'Temperatura', color: 'red' });
        StationSettings.ChartSettings.Humidity.series.push({ data: [], name: 'Vlaga', color: 'green' });
        StationSettings.ChartSettings.Pressure.series.push({ data: [], name: 'Zračni tlak', color: 'black' });
        StationSettings.ChartSettings.Rain.series.push({ data: [], name: 'Padavine', color: 'blue' });
        StationSettings.ChartSettings.Wind.series.push({ data: [], name: 'Sunki vetra', color: 'black' }, { data: [], name: 'Hitrost vetra', color: 'gray' });
        StationSettings.ChartSettings.Dewpoint.series.push({ data: [], name: 'Rosišče', color: 'orange' });
        StationSettings.ChartSettings.UV.series.push({ data: [], name: 'Sončno obsevanje', color: 'tomato'})
    }

    SetChartSeries(StationSettings) {
        StationSettings.ChartSettings.Temp.series.length = 1;
        StationSettings.ChartSettings.Humidity.series.length = 1;
        StationSettings.ChartSettings.Pressure.series.length = 1;
        StationSettings.ChartSettings.Rain.series.length = 1;
        StationSettings.ChartSettings.Wind.series.length = 2;
        StationSettings.ChartSettings.Dewpoint.series.length = 1;
        StationSettings.ChartSettings.UV.series.length = 1;
    }

    SetChartOptions(StationSettings, ChartOptions, interval) {
        StationSettings.ChartSettings.Temp.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.Humidity.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.Pressure.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.Rain.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.Wind.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.Dewpoint.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.UV.xAxis = ChartOptions.xAxis;
        StationSettings.ChartSettings.UV.yAxis.tickPositioner = function () {
            var positions = [],
                tick = Math.floor(this.dataMin),
                increment = Math.ceil((this.dataMax - this.dataMin) / 8);

            if (this.dataMax !== null && this.dataMin !== null) {
                for (tick; tick - increment <= this.dataMax; tick += increment) {
                    positions.push(tick);
                }
            }
            return positions;
        }

        if (interval == 'weekly') {
            StationSettings.ChartSettings.UV.xAxis.tickInterval = 100;
        }

        StationSettings.ChartSettings.Temp.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.Humidity.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.Pressure.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.Rain.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.Wind.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.Dewpoint.tooltip = this.GetDailyFormatter();
        StationSettings.ChartSettings.UV.tooltip = this.GetDailyFormatter();
    }

    AddChartData(StationSettings, interval) {
        console.log(this.details);
        StationSettings.ChartSettings.Temp.series[0].data = this.details.map(x => x.temperature);

        StationSettings.LastRefresh = moment(this.details[this.details.length-1].dateTime).add(2, 'hours').format('DD. MM. Y HH:mm');

        StationSettings.ChartSettings.Humidity.series[0].data = this.details.map(x => x.humidity);

        StationSettings.ChartSettings.Pressure.series[0].data = this.details.map(x => x.pressure);

        if (interval == 'daily') {
            StationSettings.ChartSettings.Rain.series[0].data = this.details.map(x => x.rainDaily);
        }
        else if (interval == 'weekly') {
            StationSettings.ChartSettings.Rain.series[0].data = this.details.map(x => x.rainWeekly);
        }
        else if (interval == 'monthly') {
            StationSettings.ChartSettings.Rain.series[0].data = this.details.map(x => x.rainMonthly);
        }
        else {
            StationSettings.ChartSettings.Rain.series[0].data = this.details.map(x => x.rain);
        }

        StationSettings.ChartSettings.Wind.series[0].data = this.details.map(x => x.windGust);
        StationSettings.ChartSettings.Wind.series[1].data = this.details.map(x => x.windSpeed);

        StationSettings.ChartSettings.Dewpoint.series[0].data = this.details.map(x => x.dewpoint);

        StationSettings.ChartSettings.UV.series[0].data = this.details.map(x => x.solarRadiation);
        if (interval == 'daily') {
            StationSettings.ChartSettings.UV.xAxis.categories = AddDailyTimeSeries();
        }
        else if (interval == 'weekly') {
            StationSettings.ChartSettings.UV.xAxis.categories = AddWeeklyTimeSeries();
        }
        else if (interval == 'monthly') {
            StationSettings.ChartSettings.UV.xAxis.categories = AddMonthlyTimeSeries();
        }
    }

    GetDailyFormatter() {
        let tool = {
            formatter: function() {
                var s = '<b>'+ moment(this.x).format('DD. MM. YYYY HH:mm') +'</b>';
        
                $.each(this.points, function(i, point) {
                        s += '<br/><span style="color:' + point.color + '">\u25CF</span> ' + point.series.name + ': ' + '<b>' + point.y + '</b>';
                });
        
                return s;
            },
            shared: true
        }
        return tool;
    }
}

class Details {
    constructor(temperature, humidity, dewpoint, windSpeed, windGust, windDirection, pressure, rain, rainDaily, rainWeekly, rainMonthly, solarRadiation, dateTime) {
        this.temperature = temperature;
        this.humidity = humidity;
        this.dewpoint = dewpoint;
        this.windSpeed = windSpeed;
        this.windGust = windGust;
        this.windDirection = windDirection;
        this.pressure = pressure;
        this.rain = rain;
        this.rainDaily = rainDaily;
        this.rainWeekly = rainWeekly;
        this.rainMonthly = rainMonthly;
        this.solarRadiation = solarRadiation;
        this.dateTime = dateTime;
    }
}

class General {
    constructor(altitude, stationType, unit) {
        this.altitude = altitude;
        this.stationType = stationType;
        this.unit = unit;
    }
}

class Units {
    constructor(dewpointUnit, humidityUnit, pressureUnit, rainUnit, solarObservationUnit, temperatureUnit, windUnit) {
        this.dewpointUnit = dewpointUnit;
        this.humidityUnit = humidityUnit;
        this.pressureUnit = pressureUnit;
        this.rainUnit = rainUnit;
        this.solarObservationUnit = solarObservationUnit;
        this.temperatureUnit = temperatureUnit;
        this.windUnit = windUnit;
    }
}

class Stats {
    constructor(
        currentTemperature,
        currentHumidity, 
        currentWindSpeed,
        currentPressure,
        currentDewpoint, 
        currentSolarRadiation,
        currentRain,
        lastUpdated,
        maxTemperature,
        maxTemperatureDate,
        minTemperature,
        minTemperatureDate,
        maxHumidity,
        maxHumidityDate,
        minHumidity,
        minHumidityDate,
        maxDewpoint,
        maxDewpointDate,
        minDewpoint,
        minDewpointDate,
        lastHourRain,
        accumulatedRain,
        dailyRain,
        weeklyRain, 
        monthlyRain,
        maxWindGust,
        maxWindGustDate,
        maxPressure,
        maxPressureDate,
        minPressure,
        minPressureDate,
        maxSolarRadiation,
        maxSolarRadiationDate,
        minSolarRadiation,
        minSolarRadiationDate,
        avgTemperature,
        avgHumidity,
        avgDewpoint,
        avgPressure,
        avgSolarRadiation,
        avgWindSpeed,
        ) {
        
        this.currentTemperature = currentTemperature;
        this.currentHumidity = currentHumidity;
        this.currentDewpoint = currentDewpoint;
        this.currentPressure = currentPressure;
        this.currentWindSpeed = currentWindSpeed;
        this.currentSolarRadiation = currentSolarRadiation;
        this.maxTemperature = maxTemperature;
        this.maxTemperatureDate = maxTemperatureDate;
        this.minTemperature = minTemperature;
        this.minTemperatureDate = minTemperatureDate;
        this.maxHumidity = maxHumidity;
        this.maxHumidityDate = maxHumidityDate;
        this.minHumidity = minHumidity;
        this.minHumidityDate = minHumidityDate;
        this.maxDewpoint = maxDewpoint;
        this.maxDewpointDate = maxDewpointDate;
        this.minDewpoint = minDewpoint;
        this.minDewpointDate = minDewpointDate;
        this.maxWindGust = maxWindGust;
        this.maxWindGustDate = maxWindGustDate;
        this.maxPressure = maxPressure;
        this.maxPressureDate = maxPressureDate;
        this.minPressure = minPressure;
        this.minPressureDate = minPressureDate;
        this.maxSolarRadiation = maxSolarRadiation;
        this.maxSolarRadiationDate = maxSolarRadiationDate;
        this.minSolarRadiation = minSolarRadiation;
        this.minSolarRadiationDate = minSolarRadiationDate;
        this.avgTemperature = avgTemperature;
        this.avgHumidity = avgHumidity;
        this.avgDewpoint = avgDewpoint;
        this.avgPressure = avgPressure;
        this.avgSolarRadiation = avgSolarRadiation;
        this.avgWindSpeed = avgWindSpeed;
        this.currentRain = currentRain;
        this.lastHourRain = lastHourRain;
        this.dailyRain = dailyRain;
        this.weeklyRain = weeklyRain;
        this.monthlyRain = monthlyRain;
        this.lastUpdated = lastUpdated;
        this.accumulatedRain = accumulatedRain;
    }
}

export {
    WeatherData,
    Units,
    Details,
    Stats,
    General
}