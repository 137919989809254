import moment from "moment";

function LatestAvailable(StationSettings, details) {
    if (details.length == 0) {
        return;
    }

    let tempArr = details.map((x) => x.temperature);
    let humArr = details.map((x) => x.humidity);
    let updated = details.map(x => x.updated);

    let tempIndex = tempArr.length;

    while (tempIndex-- && !tempArr[tempIndex]); //Assuming temperature and humidity will both be correct and null at the same time.
    if (tempArr[tempIndex] == undefined) {
        StationSettings.TableSettings.Ribnica[2].temp = '---';
        StationSettings.TableSettings.Ribnica[2].hum = '---';
        StationSettings.TableSettings.Ribnica[2].date = '---';
        return;
    }

    StationSettings.TableSettings.Ribnica[2].temp = tempArr[tempIndex];
    StationSettings.TableSettings.Ribnica[2].hum = humArr[tempIndex];
    StationSettings.TableSettings.Ribnica[2].date = moment(updated[tempIndex]).format('DD. MM. Y HH:mm');
}

export {
    LatestAvailable
}